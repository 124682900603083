<template>
    <svg class="main-logo" width="125" height="47" viewBox="0 0 125 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M70.5848 35.8007C67.3155 35.8007 64.225 35.4539 61.4473 33.7167L62.9584 25.5033H63.4587C63.4587 29.5904 64.7929 35.2876 70.985 35.2876C73.5171 35.2876 76.4875 34.2081 76.4875 31.2514C76.4875 27.0386 72.7148 25.5491 68.9438 24.0603C65.1764 22.5729 61.4106 21.0862 61.4106 16.8851C61.4106 12.1815 66.6744 10.4674 70.5181 10.4674C73.5619 10.4674 77.4297 11.1255 79.827 13.1453L78.2441 19.3369H77.7773C77.7445 19.1708 77.7123 19.0052 77.6803 18.84L77.6801 18.8394C77.1026 15.8662 76.5598 13.072 73.4528 11.6304C69.9318 9.95677 61.661 12.2289 65.0489 17.193C66.4941 19.2144 69.2224 20.3315 71.9583 21.4517C75.9821 23.0991 80.0225 24.7534 80.0225 29.3017C80.0225 34.146 74.6232 35.8007 70.5848 35.8007ZM88.0051 35.447V35.7961H96.1888V35.447L94.1433 34.7888V12.1859H96.5068C100.381 12.1859 100.318 16.545 100.285 18.7935C100.282 18.981 100.28 19.1537 100.28 19.3081H100.869L102.333 11.0831H81.8581L83.3153 19.3081H83.9048C83.9048 19.1768 83.9034 19.0325 83.9019 18.8775C83.8802 16.6466 83.8369 12.1859 87.6326 12.1859H90.0504V34.7888L88.0051 35.447ZM100.276 35.7961V35.447L102.317 34.7235L110.471 11.0831H114.609L122.777 34.7235L124.815 35.447V35.7961H116.632V35.5169L118.685 34.7584L115.798 25.4868L107.486 24.4304C107.147 25.4699 106.792 26.5456 106.423 27.645C105.322 30.9251 104.642 35.447 108.445 35.447V35.7961H100.276ZM111.648 12.1632L115.158 23.434H107.809C109.89 16.9874 111.267 12.1632 111.267 12.1632H111.648ZM38.9165 25.5105C38.9165 32.1512 42.9961 35.7961 48.2119 35.7961C55.2481 35.7961 57.2869 31.42 57.2869 25.5105V12.2524L59.3722 11.4282V11.0831H51.1727V11.4282C54.7579 12.8424 56.287 16.4972 56.287 20.2296V25.5105C56.287 30.064 55.2661 34.3119 49.6308 34.3119C44.5826 34.3119 42.9961 29.7447 42.9961 25.5105V12.2524L45.0411 11.4282V11.0831L36.8574 11.0831V11.4282L38.9165 12.2524V25.5105Z"
            fill="#2E2E2E" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M20.8578 9.02573C19.1177 8.55641 17.0052 6.47197 17.0052 6.47197C17.0052 6.47197 14.8926 8.55641 13.1525 9.02573C11.4124 9.49506 8.5475 8.75313 8.5475 8.75313C8.5475 8.75313 7.75337 11.6215 6.47954 12.9038C5.2057 14.186 2.35607 14.9853 2.35607 14.9853C2.35607 14.9853 3.09315 17.8691 2.62689 19.6207C2.16064 21.3723 0.0898438 23.4987 0.0898438 23.4987C0.0898438 23.4987 2.16064 25.6252 2.62689 27.3768C3.09315 29.1283 2.35607 32.0121 2.35607 32.0121C2.35607 32.0121 5.2057 32.8115 6.47954 34.0937C7.75337 35.3759 8.5475 38.2443 8.5475 38.2443C8.5475 38.2443 11.4124 37.5024 13.1525 37.9717C14.8926 38.4411 17.0052 40.5255 17.0052 40.5255C17.0052 40.5255 19.1177 38.4411 20.8578 37.9717C22.5979 37.5024 25.4628 38.2443 25.4628 38.2443C25.4628 38.2443 26.257 35.3759 27.5308 34.0937C28.8046 32.8115 31.6543 32.0121 31.6543 32.0121C31.6543 32.0121 30.9172 29.1283 31.3834 27.3768C31.8497 25.6252 33.9205 23.4987 33.9205 23.4987C33.9205 23.4987 31.8497 21.3723 31.3834 19.6207C30.9172 17.8691 31.6543 14.9853 31.6543 14.9853C31.6543 14.9853 28.8046 14.186 27.5308 12.9038C26.257 11.6215 25.4628 8.75313 25.4628 8.75313C25.4628 8.75313 22.5979 9.49506 20.8578 9.02573ZM17.0052 37.2198C24.5335 37.2198 30.6364 31.0767 30.6364 23.4987C30.6364 15.9208 24.5335 9.77768 17.0052 9.77768C9.47685 9.77768 3.37392 15.9208 3.37392 23.4987C3.37392 31.0767 9.47685 37.2198 17.0052 37.2198Z"
            fill="#2E2E2E" />
        <path class="main-logo__circle" fill-rule="evenodd" clip-rule="evenodd"
            d="M17.0051 35.7249C23.713 35.7249 29.1509 30.2512 29.1509 23.4991C29.1509 16.747 23.713 11.2733 17.0051 11.2733C10.2972 11.2733 4.85938 16.747 4.85938 23.4991C4.85938 30.2512 10.2972 35.7249 17.0051 35.7249Z"
            fill="#CD7300" />
    </svg>

</template>